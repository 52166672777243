export const DUBAI_PACKAGES = [
    {
        "ID": 1,
        "DestinationName": "Dubai",
        "Name": "Dubai Local Tour Package",
        "Night": "04 Night with 3 star Hotel without Burj Khalifa",
        "PricePerPerson": "AED 1376",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 5,
        "NightsCount": 4,
        "HotelStars": 3,
        "Overview": "Join our 4-night 5- Day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your Day. Day at Leisure - Enjoy your own",
        "Inclusions": "04 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 2,
        "DestinationName": "Dubai",
        "Name": "Super Saver Dubai Vacation",
        "Night": "04 Night with 3 star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 1675",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 5,
        "NightsCount": 4,
        "HotelStars": 3,
        "Overview": "Join our 4-night 5- Day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure - Enjoy your own",
        "Inclusions": "04 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour and Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show with Ticket. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 3,
        "DestinationName": "Dubai",
        "Name": "Hotel with Burj Khalifa (3 star Hotel)",
        "Night": "3 Night with 3 Star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 1451",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "DaysCount": 4,
        "NightsCount": 3,
        "HotelStars": 3,
        "AirportTransfers": "Yes",
        "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Inclusions": "3 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour and Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show with Ticket. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 4,
        "DestinationName": "Dubai",
        "Name": "Majestic Dubai",
        "Night": "4 Night with 4 Star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 2230",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 5,
        "NightsCount": 4,
        "HotelStars": 4,
        "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Day4": "Enjoy your day. Day at Leisure - Enjoy your own",
        "Inclusions": "4 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour and Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show with Ticket. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 5,
        "DestinationName": "Dubai",
        "Name": "Glittering Dubai",
        "Night": "4 Night with 4 Star Hotel without Burj Khalifa",
        "PricePerPerson": "AED 1936",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 5,
        "NightsCount": 4,
        "HotelStars": 4,
        "Overview": "Join our 4-night 5-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Day4": "Enjoy your day. Day at Leisure - Enjoy your own",
        "Inclusions": "04 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 6,
        "DestinationName": "Dubai",
        "Name": "Reasonable Dubai Package",
        "Night": "3 Night with 4 Star Hotel & Burj Khalifa",
        "PricePerPerson": "AED 1870",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 4,
        "NightsCount": 3,
        "HotelStars": 4,
        "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Inclusions": "3 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour and Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show with Ticket. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    // {
    //     "ID": 7,
    //     "DestinationName": "Dubai",
    //     "Name": "Hello",
    //     "Night": "3 Night with 4 Star Hotel without Burj Khalifa",
    //     "PricePerPerson": "AED 430",
    //     "CostBasedOn2Pax": "Yes",
    //     "ReturnAirfare": "No",
    //     "TravelInsurance": "No",
    //     "Sightseeing": "Yes",
    //     "AirportTransfers": "Yes",
    //     "DaysCount": 3,
    //     "NightsCount": 3,
    //     "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
    //     "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
    //     "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
    //     "Day3": "Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
    //     "Inclusions": "3 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
    //     "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
    //     "PaymentAndCancellationPolicy": null,
    //     "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
    //     "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
    //     "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
    //     "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    // },
    {
        "ID": 8,
        "DestinationName": "Dubai",
        "Name": "Tours in Glitzy Dubai",
        "Night": "4 Night with 5 Star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 2340",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 5,
        "NightsCount": 4,
        "HotelStars": 5,
        "Overview": "Join our 4-night 5-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Day4": "Enjoy your day. Day at Leisure - Enjoy your own",
        "Inclusions": "4 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour and Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show with Ticket. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 9,
        "DestinationName": "Dubai",
        "Name": "Breathtaking Dubai Getaway",
        "Night": "4 Night with 5 Star Hotel without Burj Khalifa",
        "PricePerPerson": "AED 2038",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 5,
        "NightsCount": 4,
        "HotelStars": 5,
        "Overview": "Join our 4-night 5-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Day4": "Enjoy your day. Day at Leisure - Enjoy your own",
        "Inclusions": "04 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 10,
        "DestinationName": "Dubai",
        "Name": "Best Selling Dubai Tour Package",
        "Night": "3 Night with 5 Star Hotel & Burj Khalifa",
        "PricePerPerson": "AED 1955",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 4,
        "NightsCount": 3,
        "HotelStars": 5,
        "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Inclusions": "3 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour and Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show with Ticket. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 11,
        "DestinationName": "Dubai",
        "Name": "Dubai Super Saver with 5 star",
        "Night": "3 Night with 5 Star Hotel without Burj Khalifa",
        "PricePerPerson": "AED 1650",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 4,
        "NightsCount": 3,
        "HotelStars": 5,
        "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Start your morning with breakfast and relax or explore the hotel amenities. We will pick you up in the afternoon and take you to the Desert Safari. An experienced safari driver will give you and your family a thrilling ride through the exciting desert by driving in the dunes and participating in some dune bashing. Enjoy a range of adventurous activities at the desert camp, from camel rides and stargazing to sandboarding, sand-skiing, and a hot air balloon ride. After an action-packed day, enjoy a fulfilling BBQ dinner and get entertained with cultural shows to experience a true Arabian spirit. Proceed to your hotel for an overnight stay.",
        "Inclusions": "3 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 12,
        "DestinationName": "Dubai",
        "Name": "Wonderful Dubai Holiday",
        "Night": "05 Night with 3 star Hotel with Abu Dhabi City Tour",
        "PricePerPerson": "AED 1760",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 3,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs. Note: For Grand Mosque Dress Code is loose long trousers or long skirts reaching the ankles and long sleeves reaching the wrists. Shoulders must be covered. No shorts for men, no tight or transparent clothing, no visible tattoos. Women must bring a scarf to cover their heads.",
        "Day6": "Back to Home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 13,
        "DestinationName": "Dubai",
        "Name": "Dazzling Dubai",
        "Night": "05 Night with 3 star Hotel with Burj Khalifa & Abu Dhabi City Tour",
        "PricePerPerson": "AED 2065",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 3,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Back to Home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 14,
        "DestinationName": "Dubai",
        "Name": "Dubai Dreamland Adventure & Wonders",
        "Night": "05 Night with 3 star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 1918",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 3,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Visit Burj Khalifa. Visit 124th Floor (Non-Prime Time) of Burj Khalifa. Free time at the Dubai Mall. In Evening Enjoy Fountain Show.",
        "Day6": "Back to Home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 15,
        "DestinationName": "Dubai",
        "Name": "Ultimate Dubai Experience",
        "Night": "05 Night with 4 star Hotel with Abu Dhabi City Tour",
        "PricePerPerson": "AED 2446",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 4,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial 'breakwater' then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Back to Home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 16,
        "DestinationName": "Dubai",
        "Name": "Dubai Treasure Hunt",
        "Night": "05 Night with 4 star Hotel with Burj Khalifa & Abu Dhabi City Tour",
        "PricePerPerson": "AED 2738",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 4,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial 'breakwater' then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 17,
        "DestinationName": "Dubai",
        "Name": "Feel the Magic of Love in Dubai",
        "Night": "05 Night with 4 star with Burj Khalifa",
        "PricePerPerson": "AED 2595",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 4,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Visit Burj Khalifa. Visit 124th Floor (Non-Prime Time) of Burj Khalifa. Free time at the Dubai Mall. In Evening Enjoy Fountain Show.",
        "Day6": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 18,
        "DestinationName": "Dubai",
        "Name": "Dubai & Abu Dhabi Wonders",
        "Night": "05 Night with 5 star Hotel with Abu Dhabi City Tour",
        "PricePerPerson": "AED 2575",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 5,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous “7-star hotel”. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Back to home. After Breakfast - Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 19,
        "DestinationName": "Dubai",
        "Name": "Dubai Explorer Family Package",
        "Night": "05 Night with 5 star Hotel with Burj Khalifa & Abu Dhabi City Tour",
        "PricePerPerson": "AED 2870",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 5,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous “7-star hotel”. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 20,
        "DestinationName": "Dubai",
        "Name": "Dubai Majestic Mix",
        "Night": "05 Night with 5 star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 2720",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 6,
        "NightsCount": 5,
        "HotelStars": 5,
        "Overview": "Join our 5-night 6-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Visit Burj Khalifa. Visit 124th Floor (Non-Prime Time) of Burj Khalifa. Free time at the Dubai Mall. In Evening Enjoy Fountain Show.",
        "Day6": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "05 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 21,
        "DestinationName": "Dubai",
        "Name": "Dubai and Abu Dhabi discoveries",
        "Night": "06 Night with 3 star Hotel with Abu Dhabi City Tour",
        "PricePerPerson": "AED 1990",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 3,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel.' Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs. Note: For Grand Mosque dress code is loose long trousers or long skirts reaching the ankles and long sleeves reaching the wrists. Shoulders must be covered. No shorts for men, no tight or transparent clothing, no visible tattoos. Women must bring a scarf to cover their heads.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 22,
        "DestinationName": "Dubai",
        "Name": "Romantic Escapes in Dubai",
        "Night": "06 Night with 3 star Hotel with Burj Khalifa & Abu Dhabi City Tour",
        "PricePerPerson": "AED 2285",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 3,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel.' Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs. Note: For Grand Mosque dress code is loose long trousers or long skirts reaching the ankles and long sleeves reaching the wrists. Shoulders must be covered. No shorts for men, no tight or transparent clothing, no visible tattoos. Women must bring a scarf to cover their heads.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 23,
        "DestinationName": "Dubai",
        "Name": "Unforgettable Dubai",
        "Night": "06 Night with 3 star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 2138",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 3,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Visit Burj Khalifa. Visit 124th Floor (Non-Prime Time) of Burj Khalifa. Free time at the Dubai Mall. In Evening Enjoy Fountain Show.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 24,
        "DestinationName": "Dubai",
        "Name": "Dubai Delights",
        "Night": "06 Night with 3 star Hotel (Basic Package)",
        "PricePerPerson": "AED 1845",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 3,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Enjoy your day. Day at Leisure.",
        "Day4": "Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day5": "Enjoy your day. Day at Leisure.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 25,
        "DestinationName": "Dubai",
        "Name": "Best of Dubai & Abu Dhabi",
        "Night": "06 Night with 4 star Hotel with Abu Dhabi City Tour",
        "PricePerPerson": "AED 2795",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 4,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs. Note: For Grand Mosque, dress code is loose long trousers or long skirts reaching the ankles and long sleeves reaching the wrists. Shoulders must be covered. No shorts for men, no tight or transparent clothing, no visible tattoos. Women must bring a scarf to cover their heads.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "HotelStars": 4,
        "Inclusions": "06 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 26,
        "DestinationName": "Dubai",
        "Name": "Romantic Gateway to Dubai - Arabian Nights & City Life",
        "Night": "06 Night with 4 star Hotel with Burj Khalifa & Abu Dhabi City Tour",
        "PricePerPerson": "AED 3096",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 4,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs. Note: For Grand Mosque, dress code is loose long trousers or long skirts reaching the ankles and long sleeves reaching the wrists. Shoulders must be covered. No shorts for men, no tight or transparent clothing, no visible tattoos. Women must bring a scarf to cover their heads.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor (Non-Prime Time) + Dubai Mall and Fountain show. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 27,
        "DestinationName": "Dubai",
        "Name": "Exciting trip to Dubai",
        "Night": "06 Night with 4 star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 2948",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 4,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Visit Burj Khalifa. Visit 124th Floor (Non-Prime Time) of Burj Khalifa. Free time at the Dubai Mall. In Evening Enjoy Fountain Show.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 28,
        "DestinationName": "Dubai",
        "Name": "Dubai Delights- Serving you just Deserts",
        "Night": "06 Night with 4 star Hotel ( Basic Package)",
        "PricePerPerson": "AED 2648",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 4,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Enjoy your day. Day at Leisure.",
        "Day4": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day5": "Enjoy your day. Day at Leisure.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 4 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 29,
        "DestinationName": "Dubai",
        "Name": "Dubai and Abu Dhabi Escapes with Grand Mosque & BAPS Temple Visit",
        "Night": "06 Night with 5 star Hotel with Abu Dhabi City Tour",
        "PricePerPerson": "AED 2958",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 5,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 30,
        "DestinationName": "Dubai",
        "Name": "Luxury Dubai",
        "Night": "06 Night with 5 star Hotel with Burj Khalifa & Abu Dhabi City Tour",
        "PricePerPerson": "AED 3256",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 5,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Abu Dhabi City Tour. Join this tour for an overview of the most visited sights in Abu Dhabi – UAE’s capital that is built on a natural island. After arriving in Abu Dhabi you stop first at Sheikh Zayed Mosque, one of the largest in the world hosting more than 30,000 worshippers. Completely clad in marble, the mosque has numerous intricate Islamic decorations. Afterwards, enjoy a photo-stop at Emirates Palace, Abu Dhabi’s famous '7-star hotel'. Take stunning pictures of the Manhattan-esque skyline from the artificial ‘breakwater’ then travel past the pristine gardens and beaches of the Corniche to visit Qasr Al Hosn, former residence of the ruling Al-Nahyan family and built around the island’s only fresh water well. Drive past Etihad Square displaying important items from UAE’s history and culture then head to Central Souq for a mingle with the locals at this charming souk where everything from gold to souvenirs can be haggled for. Later visit the traditional date market for a look at the staggering varieties of this beloved fruit before continuing to Saadiyat Island, a cultural hub and home to the stunning Louvre Abu Dhabi for photos. At the nearby Yas Island there is time for a final stop at the world's first Ferrari World theme park for souvenirs.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Abu Dhabi City Tour with Grand Mosque and BAPS Temple. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 31,
        "DestinationName": "Dubai",
        "Name": "Limitless with Dubai",
        "Night": "06 Night with 5 star Hotel with Burj Khalifa",
        "PricePerPerson": "AED 3105",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 5,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day4": "Enjoy your day. Day at Leisure.",
        "Day5": "Visit Burj Khalifa. Visit 124th Floor (Non-Prime Time) of Burj Khalifa. Free time at the Dubai Mall. In the evening, enjoy the Fountain Show.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari & Burj Khalifa 124th Floor(Non-Prime Time) + Dubai Mall and Fountain show. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 32,
        "DestinationName": "Dubai",
        "Name": "Discover Dubai",
        "Night": "06 Night with 5 star Hotel (Basic Package)",
        "PricePerPerson": "AED 2805",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "AirportTransfers": "Yes",
        "DaysCount": 7,
        "NightsCount": 6,
        "HotelStars": 5,
        "Overview": "Join our 6-night 7-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Marina cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will see the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Enjoy your day. Day at Leisure.",
        "Day4": "Desert Safari. Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Day5": "Enjoy your day. Day at Leisure.",
        "Day6": "Enjoy your day. Day at Leisure.",
        "Day7": "Back to home. Drop at Dubai Airport.",
        "Inclusions": "06 Night’ Stay at 5 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Marina. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    },
    {
        "ID": 33,
        "DestinationName": "Dubai",
        "Name": "Dubai Super Saver with 3 star",
        "Night": "3 Nights with 3 Star Hotel Basic Package",
        "PricePerPerson": "AED 1142",
        "CostBasedOn2Pax": "Yes",
        "ReturnAirfare": "No",
        "TravelInsurance": "No",
        "Sightseeing": "Yes",
        "DaysCount": 4,
        "NightsCount": 3,
        "HotelStars": 3,
        "AirportTransfers": "Yes",
        "Overview": "Join our 3-night 4-day tour to Dubai, a country bestowed endlessly by ultramodern infrastructure. Dubai is the ultimate destination for those looking for the fastest, tallest, biggest, highest, and largest experiences in the world. Whether you're a shopping fanatic, culture vulture or just looking to indulge in some luxury, Dubai has something for everyone. With its bustling metropolis, towering skyscrapers, and luxurious indulgences, this city is like nothing else. It's not just a shopping capital of the Middle East but a cosmopolitan hub that is constantly growing its business and cultural scene. This emirate of the United Arab Emirates is famous for its iconic palm island, the Burj Khalifa, and so much more. It's a city that seamlessly blends tradition with new and exciting attractions and technologies, making it a futuristic wonderland.",
        "Day1": "Welcome to Dubai. Arrive at the Dubai international airport our tour representative will meet you on your arrival at Dubai Airport. Hotel for check-in. Relax and enjoy the hotel’s hospitality. Later in the evening, you will proceed to the Creek cruise for an unforgettable experience of the Dhow Cruise, where a relaxed ambience and spectacular views await under the beaming moonlight. Proceed to your hotel for an overnight stay.",
        "Day2": "Dubai City Tour. After a hearty morning breakfast at the hotel, we will take you on the much-awaited Dubai city tour. Sample historic sites and the vibrant cosmopolitan life of Dubai on this tour starting in the popular Jumeirah district. Make a stop at the traditionally recreated Madinat Souk for stunning photos of the iconic Burj Al Arab Hotel then drive onto the man-made Palm Jumeirah island, an incredible architectural marvel for a closer look at another icon, the Atlantis Resort. Continue along Jumeirah Beach, the picturesque palace and residential area of Dubai to a photo-stop at the famed Jumeirah Mosque. Next drive to Zabeel district for a few clicks outside the Dubai Ruler’s Palace before heading to Al Bastakiya, the old part of Dubai. A brief stop at the ruling family’s former residence, Al Fahidi Fort is followed by a short Abra (water taxi) sailing across Dubai Creek. Disembark at the Spice Souk where the fragrances of the orient come together then there is time to shop in one of the most visited sites of Dubai – the Gold Souk. Finally, your journey ends in the new heart of Downtown Dubai where you will enjoy the magnificent Burj Khalifa, the world’s tallest building. After an exciting day, proceed to your hotel for an overnight stay.",
        "Day3": "Later in the afternoon proceed for Desert Safari to enjoy the thrilling dune bashing on a desert safari adventure exploring the massive sand dunes of the Arabian deserts. Enjoy BBQ Dinner and the belly dancing performance.",
        "Inclusions": "3 Nights’ Stay at 3 star Hotel or similar. Standard Room with Daily Breakfast. Dhow Cruise Creek. Dubai City Tour. Desert Safari. Roundtrip Airport Transfers. All Transfer and Tours are on a Sharing-In-Coach Basis.",
        "Exclusions": "Visa Fees & Assistance. Flight. Lunch & Dinner. Early check-in or late check-out charges are not mentioned. Optional tours or additional sightseeing, activities, and experiences outside of the itinerary. Any expenses of personal nature viz. tips to driver & Guide, Cigarettes, Laundry, Telephone Calls, Mini-bar Etc. Meals other than mentioned above. All extra incurred at the Hotel other than mentioned. Any meals if not mentioned above. & Tourism Dirham.",
        "PaymentAndCancellationPolicy": null,
        "VisaInfo": "Visa Policy: Visa Application Takes Minimum 4-5 Working Days for Issuance (Friday- Saturday immigration is closed - Subject To Immigration Approval). Transit visa is valid for 14-days stay on and the Tourist visa is valid for 30-days stay. If passenger will stay more, penalty will be charged accordingly. Client must travel within 7-10 days once visa is issued. Cancellation for visa applications will be charged at an additional cost of USD 45/- per visa. We are not responsible in case of delayed and rejection of visa.",
        "Terms&Conditions": "The above rates are quoted in USD, Nett and non-commissionable to you. Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end. The above quote is valid only for this query and may vary in case the number of passengers change. Please be reminded that all special requests like early check-in, smoking, nonsmoking, views, floors, king, twin, adjoining / interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior. If the Hotel or Suppliers change their Quote for the services that they offer, the same will be forwarded to your Company. Burj Khalifa tour once booked cannot be cancelled, also be informed the Burj Khalifa tour charge is nonrefundable and non-transferable. Kindly note that during Major Exhibitions/Events additional surcharge will be applicable, Surcharge may vary depending on the Hotels and will be advised at the time of final confirmation.",
        "Note": "Above Rooms & Rates are subject to availability at the time of booking confirmation, Tourism Dirham Paid by the guest at the time of check-in at Hotel. As per DTCM Rules, based on the Executive Council Resolution No 2 of 2014. With effect from 31 March 2014, Every Guest has to pay Tourism Dirham that is AED 7/- per room per night for all 2* hotels, AED 10/- per room per night for all 3* Hotels, AED 15/- per room per night for all 4 * And AED 20/- per room per night for all 5* Hotels, at the time of check-in.",
        "CheckinCheckoutPolicy": "Official check-in 1400hrs & Check-out 1200noon. Early Check-In and Late Check-Out is subject to hotel availability at the time of check-in/out."
    }
]